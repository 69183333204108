import React, { useState } from "react"

import Lightbox from "react-image-lightbox"

import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components//atoms/Image"
import { Helmet } from "react-helmet"

const ThumbnailGalleryBlock = ({ block }) => {
  const [isOpen, setIsOpen] = useState(0)
  const [imageIndex, setImageIndex] = useState(0)

  const images = []
  for (var i = 0; i < block.image_items.length; i++) {
    images.push(block.image_items[i].image.filename)
  }

  let bodyClass = ""
  if (Helmet.peek()) {
    const helmetAttributes = Helmet.peek()
    bodyClass = helmetAttributes.bodyAttributes.class
  }
  return (
    <BlockWrapper block={block} showHeadline="true">
      <div
        className={`flex flex-row flex-wrap ${
          block.headline?.length > 0 ? "pt-4" : ""
        } -mx-grid`}
      >
        {block.image_items &&
          block.image_items.length > 0 &&
          block.image_items.map((item, index) => (
            <div
              className="w-1/2 mt-4 sm:w-1/3 md:w-1/4 lg:w-1/6 px-grid group"
              key={index}
            >
              <button
                className="block w-full"
                onClick={() => {
                  setIsOpen(item)
                  setImageIndex(index)
                }}
              >
                <Image
                  image={item.image}
                  className="object-contain w-full h-full"
                  aspectRatio="1by1"
                  fitIn={item.image_fitin}
                />
              </button>
              {(item.caption?.length > 0 || item.image?.title?.length > 0) && (
                <p className="mt-2 prose-sm line-clamp-2 group-hover:text-ro-red">
                  {item.caption?.length > 0
                    ? item.caption
                    : item.image?.title?.length > 0
                    ? item.image.title
                    : ""}
                </p>
              )}
            </div>
          ))}
      </div>

      {!!isOpen && (
        <>
          <Helmet
            bodyAttributes={{
              class: bodyClass + " overflow-hidden",
            }}
          />
          <Lightbox
            mainSrc={images[imageIndex]}
            nextSrc={images[(imageIndex + 1) % images.length]}
            prevSrc={images[(imageIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setImageIndex((imageIndex + images.length - 1) % images.length)
            }
            onMoveNextRequest={() =>
              setImageIndex((imageIndex + 1) % images.length)
            }
            imageCaption={`Bild ${imageIndex + 1}/${
              block.image_items.length
            }: ${
              block.image_items[imageIndex].caption?.length > 0
                ? block.image_items[imageIndex].caption
                : block.image_items[imageIndex].image?.title?.length
                ? block.image_items[imageIndex].image.title
                : ""
            } ${
              block.image_items[imageIndex].image?.copyright?.length > 0
                ? "| © " + block.image_items[imageIndex].image?.copyright
                : ""
            }`}
          />
        </>
      )}
    </BlockWrapper>
  )
}

export default ThumbnailGalleryBlock
